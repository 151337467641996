<template>
	<div>
		<div
			class="parent-app"
			:style="
				'backgroundColor:' +
				parentCredential.students[parentCredential.selectedStudent].bg_color +
				'; backgroundImage:url(' +
				parentCredential.students[parentCredential.selectedStudent].bg_image +
				')'
			"
		>
			<div class="container">
				<div class="va-row">
					<div class="flex xs6 logo text-left">
						<img
							:src="require('@/assets/images/logo-ddh-blue.png')"
							alt
							class
						/>
					</div>

					<div class="flex xs6 text-right itens-menu">
						<button @click="scrollParent('faq')">DÚVIDAS?</button>
						<router-link class :to="{ name: 'main-ftd' }">
							SAIR
							<i class="fa fa-sign-out" aria-hidden="true"></i>
						</router-link>
					</div>
				</div>
			</div>

			<div class="container parents">
				<div class="va-row text-left">
					<div class="flex md8 xs12 text-left parents-name">
						<h3 v-if="parentCredential.students.length === 1">
							{{
								parentCredential.students[parentCredential.selectedStudent].name
							}}
						</h3>

						<div class="dropdown-name" v-else>
							<h3 @mouseover="showBox(true)" @click="showBox(true)">
								{{
									parentCredential.students[parentCredential.selectedStudent]
										.name
								}}
								<i
									class="fa fa-chevron-down"
									:style="
										'color:' +
										parentCredential.students[parentCredential.selectedStudent]
											.bg_color
									"
								></i>
							</h3>
							<div class="menu-dropdown" v-if="dropdownIsOpen">
								<a
									v-on:click="setAccount(id)"
									:key="id"
									v-for="(item, id) in parentCredential.students"
									>{{ item.name }}</a
								>
							</div>
						</div>

						<span
							>Escola:
							{{
								parentCredential.students[parentCredential.selectedStudent]
									.school_name
							}}</span
						>
					</div>

					<div class="flex md4 xs12 text-right parents-name">
						<router-link :to="{ name: 'login-parents-step-1' }" class="more-son"
							>+ OUTRO FILHO</router-link
						>
					</div>
				</div>

				<div class="book-item">
					<div class="container">
						<div class="va-row">
							<div class="flex md4 xs12 block-cover">
								<div
									class="icon-alert"
									@click="showPopUpHover()"
									v-if="
										parentCredential.students[parentCredential.selectedStudent]
											.status === 'pending'
									"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="36"
										height="36"
										viewBox="0 0 36 36"
									>
										<path
											fill="#000"
											fill-rule="evenodd"
											d="M18.937 25.28c-.247.252-.59.399-.937.399-.348 0-.69-.147-.94-.4a1.383 1.383 0 0 1 0-1.92c.25-.253.59-.397.94-.397.347 0 .69.144.937.397a1.375 1.375 0 0 1 0 1.92zm-1.975-10.34A1.05 1.05 0 0 1 18 13.876a1.05 1.05 0 0 1 1.038 1.062v6.337A1.05 1.05 0 0 1 18 22.34a1.05 1.05 0 0 1-1.038-1.063V14.94zM28.68 25.031L19.606 8.951A1.85 1.85 0 0 0 17.997 8c-.664 0-1.277.362-1.61.95L7.25 25.149a1.94 1.94 0 0 0 0 1.901c.332.589.945.951 1.61.951H27.141C28.168 28 29 27.149 29 26.099c0-.395-.118-.763-.32-1.067z"
										/>
									</svg>

									<div class="flag-info" v-if="showPopUp">
										<div class="va-row">
											<div class="flex xs12">
												<b class="flag-title">Compra do livro pendente</b>
												<p>
													O livro personalizado será criado em sala de aula, e
													entregue após a realiazação das atividades em sala de
													aulas
													<button
														@click="scrollParent('inside-ddh')"
														class="btn btn-orange"
													>
														COMPRAR AGORA
													</button>
												</p>
											</div>
										</div>

										<div class="close">
											<buttton @click.stop.prevent="hidePopUp()">x</buttton>
										</div>
									</div>
								</div>

								<img
									:src="
										parentCredential.students[parentCredential.selectedStudent]
											.product.thumbnail
									"
									alt
									class
								/>
							</div>

							<div class="flex md8 xs12">
								<h4>
									{{
										parentCredential.students[parentCredential.selectedStudent]
											.product.name
									}}
								</h4>
								<p>
									{{
										parentCredential.students[parentCredential.selectedStudent]
											.product.content_text
									}}
								</p>

								<span>
									<b>Protagonista:</b>
									{{
										parentCredential.students[parentCredential.selectedStudent]
											.name
									}}
									&nbsp;
									<!-- <b>Autora:</b> Maria Amália Camargo -->
								</span>

								<h5>Campos de desenvolvimento</h5>

								<div
									v-html="
										parentCredential.students[parentCredential.selectedStudent]
											.product.content_text_2
									"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="inside-ddh"
			id="inside-ddh"
			:style="
				'backgroundColor:' +
				parentCredential.students[parentCredential.selectedStudent].bg_color
			"
		>
			<!-- TIMELINE BLOCK -->
			<div class="container">
				<div class="va-row">
					<div class="flex md6 xs12 info-about-ddh no-padding">
						<h4>Propósito do Dentro Educa</h4>
						<p>
							Na educação do século 21 os alunos devem ser protagonistas de seus
							aprendizados. Hoje, incentivar a participação ativa das crianças
							na sala de aula é essencial para gerar engajamento, promover a
							autoconfiança e a vontade de aprender dos alunos. Com o propósito
							de estimular o protagonismo através da leitura, o projeto Dentro
							Educa transforma cada aluno em personagem de um livro
							personalizado exclusivo. A criança fará parte de uma história
							única, que proporciona uma experiência inesquecível de leitura e
							desenvolve suas competências socioemocionais. E assim como os
							aprendizados trazidos, o livro se tornará uma valiosa lembrança
							para a vida toda.
						</p>
					</div>

					<div class="flex md6 xs12 no-padding">
						<div class="item-box">
							<span class="label-status"
								>{{
									parentCredential.students[parentCredential.selectedStudent]
										.percentage
								}}% das famílias já aderiram o projeto</span
							>
							<div class="va-row">
								<div
									class="va-row"
									v-if="
										parentCredential.students[parentCredential.selectedStudent]
											.status === 'pending' && !emailOrderIsSent
									"
								>
									<div class="flex xs4">
										<img
											:src="
												parentCredential.students[
													parentCredential.selectedStudent
												].product.thumbnail
											"
											alt
											class
										/>
									</div>

									<div class="flex xs8 align-bottom">
										<span>Local da entrega:</span>
										<p>
											Depois de serem criados em sala de aula os livros serão
											enviados diretamente para a escola.
										</p>
										<span>Formas de pagamento:</span>
										<ul>
											<li>Boleto Bancário único</li>
											<li>A vista no cartão de crédito</li>
										</ul>
									</div>

									<div class="flex xs12">
										<div class="va-row">
											<div class="flex md6 xs12">
												<button
													class="btn btn-primary"
													@click="createOrder('credit_card')"
												>
													PAGAR COM CARTÃO
												</button>
											</div>
											<div class="flex md6 xs12">
												<button
													class="btn btn-primary"
													@click="createOrder('bank_slip')"
												>
													PAGAR COM BOLETO
												</button>
											</div>
										</div>
									</div>
								</div>

								<div
									class="flex xs12 text-center sent-email"
									v-else-if="emailOrderIsSent"
								>
									Um e-mail foi enviado com os dados para pagamento,
									<br />confira sua caixa de e-mail.
								</div>

								<div
									class="flex xs12"
									v-if="
										parentCredential.students[parentCredential.selectedStudent]
											.status !== 'pending' && !emailOrderIsSent
									"
								>
									<div class="va-row">
										<div class="flex xs12 md12 timeline-pedidos timeline-info">
											<div class="va-row icons">
												<div
													class="flex xs12 md4 text-center no-padding first"
													:class="
														parentCredential.students[
															parentCredential.selectedStudent
														].schedule.payment.status
															? 'active'
															: 'inactive'
													"
												>
													<img
														:src="
															require('@/assets/images/ico-2-' +
																(parentCredential.students[
																	parentCredential.selectedStudent
																].schedule.payment.status
																	? 'active'
																	: 'inactive') +
																'.svg')
														"
														width="62"
														alt
														class
													/>
													<div
														class="flex xs12 no-padding item-line"
														:class="
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.payment.status
																? 'active'
																: 'inactive'
														"
													>
														<span></span>
													</div>
													<div
														class="flex xs12 md12 no-padding text-center"
														:class="
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.payment.status
																? 'active'
																: 'inactive'
														"
													>
														<b>{{
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.payment.label
														}}</b>
														{{
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.payment.date
														}}
													</div>
												</div>

												<div
													class="flex xs12 md4 text-center no-padding"
													:class="
														parentCredential.students[
															parentCredential.selectedStudent
														].schedule.created.status
															? 'active'
															: 'inactive'
													"
												>
													<img
														:src="
															require('@/assets/images/ico-1-' +
																(parentCredential.students[
																	parentCredential.selectedStudent
																].schedule.created.status
																	? 'active'
																	: 'inactive') +
																'.svg')
														"
														width="62"
														alt
														class
													/>
													<div
														class="flex xs12 no-padding item-line"
														:class="
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.created.status
																? 'active'
																: 'inactive'
														"
													>
														<span></span>
													</div>
													<div
														class="
															flex
															xs12
															md12
															no-padding
															text-center
															offset--md1
														"
														:class="
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.created.status
																? 'active'
																: 'inactive'
														"
													>
														<b>{{
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.created.label
														}}</b>
														{{
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.created.date
														}}
													</div>
												</div>

												<div
													class="flex xs12 md4 text-center no-padding"
													:class="
														parentCredential.students[
															parentCredential.selectedStudent
														].schedule.delivered.status
															? 'active'
															: 'inactive'
													"
												>
													<img
														:src="
															require('@/assets/images/ico-4-' +
																(parentCredential.students[
																	parentCredential.selectedStudent
																].schedule.delivered.status
																	? 'active'
																	: 'inactive') +
																'.svg')
														"
														width="62"
														alt
														class
													/>
													<div
														class="flex xs12 no-padding item-line"
														:class="
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.delivered.status
																? 'active'
																: 'inactive'
														"
													>
														<span></span>
													</div>
													<div
														class="flex xs12 md12 no-padding text-center"
														:class="
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.delivered.status
																? 'active'
																: 'inactive'
														"
													>
														<b>{{
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.delivered.label
														}}</b>
														{{
															parentCredential.students[
																parentCredential.selectedStudent
															].schedule.delivered.date
														}}
													</div>
												</div>

												<div
													class="flex xs12 md12 payment-link no-padding"
													v-if="
														parentCredential.students[
															parentCredential.selectedStudent
														].status === 'waiting'
													"
												>
													Deseja realizar o pagamento?
													<br />
													<a
														:href="
															parentCredential.students[
																parentCredential.selectedStudent
															].payment.url
														"
														target="_blank"
														>Clique aqui</a
													>
												</div>

												<!-- {{parentCredential.students[parentCredential.selectedStudent].invoice}} -->

												<div
													class="flex xs12 md12 payment-link no-padding"
													v-if="
														parentCredential.students[
															parentCredential.selectedStudent
														].invoice.url
													"
												>
													Deseja visualizar a nota fiscal?
													<br />
													<a
														:href="
															parentCredential.students[
																parentCredential.selectedStudent
															].invoice.url
														"
														target="_blank"
														>Clique aqui</a
													>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- READ ONLINE BLOCK -->
			<div
				class="container info-read-crop"
				v-if="
					parentCredential.students[parentCredential.selectedStudent].status ===
						'paid' &&
					parentCredential.students[parentCredential.selectedStudent].uuid !=
						null
				"
			>
				<div class="va-row">
					<div class="flex md6 xs12">
						<div class="va-row">
							<div class="flex xs4">
								<img
									:src="
										parentCredential.students[parentCredential.selectedStudent]
											.product.thumbnail
									"
									alt
									class
								/>
							</div>

							<div class="flex md8 xs12 info-text">
								<p>
									<b>Leia o livro também na versão online.</b><br />
									Aqui você pode ler o livros na versão digital de onde e a hora
									que quiser.
								</p>
							</div>

							<div class="flex xs12">
								<router-link
									class="read-more"
									:to="{ name: 'parents-preview' }"
								>
									LER ONLINE <i class="fa fa-chevron-right"></i>
								</router-link>
							</div>
						</div>
					</div>

					<div
						class="flex md6 xs12 info-crop text-center"
						v-if="parentCredential.settings.can_upload_picture"
					>
						<div>
							<img
								:src="require('@/assets/group-4.png')"
								alt="Dentro Educa"
								class
							/>

							<p>
								<b>Torne esse livro ainda mais especial</b>
								<br />
								Você pode personalizar o livro com uma foto da família.
								<br />
								Quanto mais fotos de boa qualidade, mais lindo ficará o livro
							</p>

							<input
								type="file"
								id="files"
								class="hide"
								ref="files"
								@change="onFileChange"
							/>

							<a
								class="btn-crop"
								href="#"
								target="_blank"
								@click.prevent="showFileChooser"
								><i class="fa fa-camera"></i> CARREGAR FOTO</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section
			class="faq"
			id="faq"
			v-if="faq.length"
			:style="
				'backgroundColor:' +
				parentCredential.students[parentCredential.selectedStudent].bg_color
			"
		>
			<div class="container">
				<div class="va-row questions">
					<div class="flex xs12">
						<h1>Perguntas frequentes</h1>

						<div>
							<h4>Como funciona?</h4>
							<vuestic-tree-root ref="treeView">
								<vuestic-tree-root ref="treeView">
									<vuestic-tree-category
										v-for="(question, id) in faq"
										:key="id"
									>
										<div class="va-row" slot="content-line">
											<div class="flex xs12 no-padding">
												{{ question.question }}
											</div>
										</div>
										<vuestic-tree-node>
											<div class="va-row">
												<div
													class="flex xs12 no-padding"
													v-html="question.answer"
												></div>
											</div>
										</vuestic-tree-node>
									</vuestic-tree-category>
								</vuestic-tree-root>
							</vuestic-tree-root>
						</div>
					</div>
				</div>
			</div>
		</section>

		<vuestic-modal
			:show.sync="url"
			:titleDisabled="false"
			v-bind:large="true"
			id="cropModal"
			ref="cropModal"
			class="crop-upload-body"
			:okText="'modal.confirm' | translate"
			:cancelText="'modal.cancel' | translate"
			:noButtons="true"
		>
			<div class="close"></div>

			<div v-if="!loadingCrop">
				<div class="va-row">
					<h1>Carregar foto</h1>
					<hr />
					<p>A foto será aplicada quadrada no livro.</p>
				</div>

				<vue-cropper
					v-if="url"
					ref="cropper"
					:aspect-ratio="4 / 4"
					:src="url"
					alt="Crop Image"
					preview=".preview"
				>
				</vue-cropper>

				<hr />

				<div class="va-row">
					<div class="flex xs12 md6">
						<a href="#" class="cancel-modal" @click.prevent="closeModal"
							>CANCELAR</a
						>
					</div>
					<div class="flex xs12 md6 text-right">
						<a href="#" class="crop-modal" @click.prevent="cropImage">ENVIAR</a>
					</div>
				</div>
			</div>

			<div v-else class="text-center">
				<h1>Estamos carregando sua foto</h1>
				<hr />
				<p>Aguarde alguns instantes</p>

				<div id="loading"></div>
			</div>
		</vuestic-modal>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";
	import VueCropper from "vue-cropperjs";
	import "cropperjs/dist/cropper.css";

	const axios = require("axios");

	export default {
		name: "login-user",
		computed: {
			...mapGetters({
				parentCredential: "parentCredential",
				// ...
			}),
		},

		components: { VueCropper },

		data() {
			return {
				name: null,
				gender: null,
				showPopUp: false,
				questions: [
					{
						question: "Pergunta",
						answer: "Resposta",
					},
				],

				payment: [
					{
						question: "Pergunta",
						answer: "Resposta",
					},
				],

				faq: [],
				dropdownIsOpen: false,

				emailOrderIsSent: false,

				files: null,

				url: null,
				cropImg: null,

				loadingCrop: false,
			};
		},
		mounted() {
			const self = this;

			let config = {
				headers: {
					Authorization: "Bearer " + self.parentCredential.token,
				},
			};

			axios
				.get(process.env.VUE_APP_ROOT_API + "/faq", config)
				.then((response) => {
					self.faq = response.data.data.faq;
				})
				.catch((e) => {
					this.errors.push(e);
				});
		},
		methods: {
			showBox(open) {
				this.dropdownIsOpen = open;
			},

			createOrder(paymentType) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + self.parentCredential.token,
					},
				};

				var data = {
					user_id:
						self.parentCredential.students[
							self.parentCredential.selectedStudent
						].student_id,
					type: paymentType,
				};

				axios
					.post(
						process.env.VUE_APP_ROOT_API + "/parents/create_order",
						data,
						config
					)
					.then((response) => {
						this.emailOrderIsSent = true;
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			setAccount(currentStudent) {
				const self = this;

				self.$store.commit("SAVE_PARENT_CURRENT_STUDENT", currentStudent);
				this.dropdownIsOpen = false;
				this.getAccountInfo();

				if (
					self.parentCredential.students[self.parentCredential.selectedStudent]
						.schedule.created.status === "pending"
				) {
					self.emailOrderIsSent = true;
				} else {
					self.emailOrderIsSent = false;
				}
			},

			getAccountInfo() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + self.parentCredential.token,
					},
				};

				axios
					.get(process.env.VUE_APP_ROOT_API + "/parents", config)
					.then((response) => {
						let parentCredential = {
							token: response.data.data.token,
							user: response.data.data.user,
							main_product: response.data.data.main_product,
							students: response.data.data.students,
							settings: response.data.data.settings,
							selectedStudent: self.parentCredential.selectedStudent,
						};

						self.$store.commit("SAVE_PARENT_CREDENTIALS", parentCredential);
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			scrollParent(slug) {
				//this.$router.push({ name: slug })
				var elmnt = document.getElementById(slug);
				this.scrollTo(document.body, elmnt.offsetTop, 600);
				this.showPopUp = false;
			},

			scrollTo(element, to, duration) {
				if (duration <= 0) return;
				var difference = to - element.scrollTop;
				var perTick = (difference / duration) * 10;

				setTimeout(function () {
					element.scrollTop = element.scrollTop + perTick;
					if (element.scrollTop === to) return;
					scrollTo(element, to, duration - 10);
				}, 10);
			},

			showPopUpHover() {
				this.showPopUp = true;
			},

			hidePopUp() {
				this.showPopUp = false;
			},

			changeGender(gender) {
				this.gender = gender; //this.studentCredential.main_product.gender[gender]
				//this.studentCredential.main_product.type_id =
			},

			sendChild() {
				const self = this;

				this.$validator.validateAll().then((result) => {
					if (result) {
						//console.log(self.login);
						let subscription = {
							product: {
								builderCharacterId:
									self.studentCredential.main_product.character_id,
								builderProductId:
									self.studentCredential.main_product.builder_id,
								characterSlug: "1",
								characterType:
									self.studentCredential.main_product.gender[self.gender],
							},

							subscription: {
								characterBirthdate: self.studentCredential.user.birthdate,
								name: self.name,
								//
								id: "1",
								subscriptionStackId: "1",
							},
						};

						self.studentCredential.subscription = subscription;
						self.$store.commit(
							"SAVE_STUDENT_CREDENTIALS",
							self.studentCredential
						);
						self.$router.push({
							name: "student-step-2",
							params: { isTeacher: self.isTeacher, grade: self.grade },
						});
						// self.$router.push({ name: 'student-step-2', params: { subscription: subscription } })
					}
				});
			},

			submitFiles() {
				const self = this;

				let formData = new FormData();
				formData.append("file", self.dataURLtoFile(self.cropImg, "imageCrop"));
				formData.append(
					"student_id",
					self.parentCredential.students[self.parentCredential.selectedStudent]
						.student_id
				);

				self.loadingCrop = true;

				axios
					.post(process.env.VUE_APP_ROOT_API + "/parents/upload", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + self.parentCredential.token,
						},
					})
					.then(function () {
						//alert('Upload feito com sucesso');
						self.loadingCrop = false;
						self.$router.push({ name: "parents-preview" });
					})
					.catch(function () {
						self.loadingCrop = false;
						alert("Erro, upload falhou");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				console.log(this.$refs.files.files);
				//this.submitFiles();
			},

			onFileChange(e) {
				const self = this;

				var file = e.target.files[0];

				var img = new Image();
				var objectUrl = URL.createObjectURL(file);
				//var urlFinal =

				img.onload = function () {
					if (this.width > 600) {
						self.url = objectUrl;
						//URL.revokeObjectURL(objectUrl);
						self.$refs.cropModal.open();
					} else {
						alert("A imagem deve ter ao menos 600px de resolução");
					}
				};

				img.src = objectUrl;
			},

			showFileChooser() {
				this.$refs.files.click();
			},

			closeModal() {
				const self = this;

				self.url = null;
				self.$refs.cropModal.close();
			},
			cropImage() {
				const self = this;

				// get image data for post processing, e.g. upload or setting image src
				self.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
				self.submitFiles();
			},

			dataURLtoFile(dataurl, filename) {
				var arr = dataurl.split(","),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);

				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}

				return new File([u8arr], filename, { type: mime });
			},
		},
	};
</script>

<style lang="scss">
	.payment-link {
		font-size: 14px;
		margin-top: 10px;
	}
	.dropdown-name {
		position: relative;
		float: left;
		z-index: 3;

		.fa {
			border-radius: 25px;
			border: solid 1px #dfdae4;
			background-color: #ffffff;
			font-size: 20px;
			width: 40px;
			height: 40px;
			text-align: center;
			float: right;
			line-height: 38px;
			margin-top: 8px;
			display: inline-block;
			margin-left: 10px;
			// color: #1ad3d9;
		}

		&:hover {
			.menu-dropdown {
				display: block;
			}
		}
	}
	.menu-dropdown {
		display: none;
		position: absolute;
		top: 90px;
		border-top: 10px solid #907feb;
		border-radius: 10px;
		border-radius: 10px;
		-webkit-box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
		box-shadow: 0 2px 4px 0 rgba(175, 162, 186, 0.4);
		background-color: #ffffff;
		padding: 20px;
		padding-top: 0px;
		right: 0;
		z-index: 4;
		padding-bottom: 0px;
		top: 53px;
		left: 0;
		max-width: 190px;
		margin-left: auto;
		margin-right: auto;

		a {
			font-family: Nunito;
			font-size: 14px !important;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #68596e;
			border-bottom: 1px solid #dfdae4;
			width: 100%;
			display: block;
			height: 57px;
			position: relative;
			line-height: 60px;
			display: block;
			width: 100%;

			background: none !important;
			color: #000 !important;
			border: 0;
			margin: 10px 0px !important;

			width: 100% !important;

			&:hover {
				color: #000;
			}

			&:last-child {
				border-bottom: 0px;
			}

			&:before {
				content: "\F054";
				color: #907feb;
				position: absolute;
				top: 11px;
				width: 4px;
				height: 4px;
				font: normal normal normal 14px/1 FontAwesome;
				right: 9px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		&:before {
			content: "\F0D8";
			color: #907feb;
			position: absolute;
			top: -20px;
			width: 4px;
			height: 4px;
			font: normal normal normal 14px/1 FontAwesome;
			right: 0;
			left: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.parent-app {
		// background-color: #025160 !important;
		background-size: cover;
		background-position: center center;

		h3 {
			height: 54px;
			font-family: Nunito;
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #ffffff;
			margin-bottom: 0px;
		}

		.flag-info {
			box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
			background-color: #feaf00 !important;
			max-width: 310px;
			padding: 10px;
			width: 310px;
			position: absolute;
			color: #fff !important;
			font-size: 14px;
			font-family: "Nunito";
			border-radius: 10px;
			display: block;
			text-align: left;
			font-weight: 200;
			bottom: 0px;
			top: -10%;
			left: 90px;
			min-height: 175px;

			.btn-orange {
				border: 0px;
				border-radius: 40px;
				background-color: #f96f00;
				margin-top: 15px;
				float: right;
			}

			p {
				margin-bottom: 0px !important;
			}

			.icon {
				align-items: center;
				display: flex;
			}

			b.flag-title {
				color: #fff !important;
			}

			&:before {
				display: none !important;
				// content: '';
				position: absolute;
				bottom: 0;
				top: 40%;
				width: 0px;
				height: 0px;
				border-top: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-right: 10px solid #feaf00 !important;
				left: -10px;
			}

			.close {
				font-size: 16px;
				position: absolute;
				top: 15px;
				right: 15px;
				color: white;
				cursor: pointer;
			}
		}
	}

	.not-found {
		h1 {
			text-align: center;
			border-bottom: 0px;
		}
	}
	.align-bottom {
		align-self: flex-end;
	}
	.info-about-ddh {
		align-self: center;

		@media (max-width: 990px) {
			padding-top: 20px !important;
		}
	}

	.parent-app {
		padding-bottom: 60px;
	}

	.square-with-icon {
		background: none !important;
		font-size: 30px !important;
	}

	.block-cover {
		position: relative;

		@media (max-width: 990px) {
			margin-top: 40px;
		}
	}

	.icon-alert {
		width: 60px;
		height: 60px;
		border-radius: 100px;
		box-shadow: 0 0 20px 0 #025160, 0 2px 4px 0 rgba(124, 44, 124, 0.5);
		background-color: #feaf00;
		text-align: center;
		color: white;
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		top: -30px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		svg {
			path {
				fill: #fff;
			}
		}
	}
	.vuestic-tree-root {
		padding: 0px !important;
	}

	.vuestic-tree-category {
		border-bottom: 1px solid #013f4b;
		padding-bottom: 15px !important;
		padding-left: 0px;
	}
	.vuestic-tree-category__list-internal-container {
		border: none !important;
		background-color: transparent !important;
		font-size: 14px !important;
		color: rgba(255, 255, 255, 0.8) !important;
	}

	.item-box {
		@media (max-width: 990px) {
			padding-top: 40px !important;
		}
	}

	.book-item {
		h4 {
			color: rgba(255, 255, 255, 0.8);
			margin-top: 0px;
		}
		p {
			font-family: "Roboto";
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: normal;
			color: #ffffff;
		}
		span {
			font-family: Roboto;
			font-size: 16px;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: normal;
		}
		h5 {
			font-family: Roboto;
			font-size: 16px;
			font-style: normal;
			line-height: 1.31;
			letter-spacing: normal;
		}
	}

	.inside-ddh {
		// background-color:#013f4b;
		padding: 80px;
		position: relative;

		@media (max-width: 990px) {
			padding: 20px;
		}

		&:before {
			content: "";
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			left: 0;
			top: 0;
		}

		.container {
			position: relative;
			z-index: 2;
		}

		.item-box {
			//opacity: 0.1;
			border-radius: 15px;
			border: solid 2px rgba(255, 255, 255, 0.1);
			padding: 29px;
			position: relative;

			.label-status {
				width: 229px;
				height: 38.5px;
				background-color: #9e68e8;
				color: rgba(255, 255, 255, 0.7);
				position: absolute;
				top: -2px;
				font-size: 12px;
				text-align: center;
				border-radius: 0px 0px 15px 15px;
				padding-top: 10px;
				right: 30px;
			}

			h4 {
				color: rgba(255, 255, 255, 0.7);
				margin-top: 0px;
			}

			span {
				color: rgba(255, 255, 255, 0.7);
				font-size: 16px;
			}

			p {
				color: rgba(255, 255, 255, 0.8);
				font-size: 16px;
			}

			li {
				color: rgba(255, 255, 255, 0.7);
				font-size: 14px;
			}
		}

		h4 {
			margin-top: 0px;
			color: rgba(255, 255, 255, 0.8);
		}

		p {
			color: rgba(255, 255, 255, 0.8);
			font-size: 16px;
		}

		.btn-primary {
			width: 100%;
			padding: 20px;
			border-radius: 30px;
			margin-top: 20px;
		}
	}

	.parents {
		color: rgba(255, 255, 255, 0.8);
	}

	.logo {
		margin-top: 20px;
	}

	.itens-menu {
		margin-top: 20px;

		a {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #ffffff;
			margin-left: 30px;
			background: none;
			border: 0px;
		}

		button {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #ffffff;
			margin-left: 30px;
			background: none;
			border: 0px;
		}
	}
	.parents-name {
		padding-bottom: 245px !important;

		@media (max-width: 990px) {
			padding-bottom: 0px !important;
		}

		h3 {
			margin-top: 0px;
			float: left;
		}

		a {
			display: block;
			float: left;
			width: 150px;
			height: 35px;
			// opacity: 0.15;
			border-radius: 100px;
			background-color: rgba(0, 0, 0, 0.3);
			text-align: center;
			// height: 14px;
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #ffffff;
			padding: 10px;
			margin-left: 15px;
			margin-top: 10px;

			&.more-son {
				float: right;
			}
		}

		span {
			width: 100%;
			float: left;
		}
	}

	.faq {
		padding-top: 60px;
		font-size: 40px;
		// background-color:#025160;

		h1 {
			color: rgba(255, 255, 255, 0.7);
			font-size: 40px;
		}

		h4 {
			font-size: 20px;
			font-weight: 800;
			font-style: normal;
			font-stretch: normal;
			line-height: 2.35;
			letter-spacing: 3.5px;
			color: rgba(255, 255, 255, 0.7);
			border-bottom: 1px solid rgba(255, 255, 255, 0.7) !important;
		}

		.vuestic-tree-category__header {
			color: rgba(255, 255, 255, 0.7);
		}

		.vuestic-tree-category {
			color: rgba(255, 255, 255, 0.7);
		}
	}

	.not-found {
		margin-bottom: 30px;

		h4 {
			border-bottom: none !important;
			margin-bottom: 0px;
		}

		p {
			font-size: 16px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: 0.89px;
			text-align: center;
			color: #fff;

			a {
				color: #4965bc;
			}
		}
	}

	.timeline-pedidos {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.75;
		letter-spacing: normal;
		text-align: center;
		color: #b3c8cc;

		b {
			color: #ffffff;
			font-weight: bold;
			font-size: 14px;

			margin-top: 17px;
			display: block;
		}

		img {
			margin-bottom: 12px;
		}

		.item-line {
			margin-left: -50%;
			span {
				&:before {
					right: 0;
					left: 100%;
					margin: 0;
				}
			}

			&.active {
				span {
					background-color: #00dcdf;
					&:before {
						background-color: #00dcdf;
					}
				}
			}
		}

		.first {
			.item-line {
				span {
					width: 0px;
					float: right;
				}
				&.active {
					span {
						&:before {
							background-color: #00dcdf;
						}
					}
				}
			}
		}

		span {
			height: 4px;
			border-radius: 0px;
			background-color: #dfdae4;
			display: block;

			// @media(max-width:480px){
			//   display: none;
			// }

			&:before {
				content: "";
				display: block;
				width: 12px;
				height: 12px;
				border-radius: 120px;
				background-color: #dfdae4;
				position: relative;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
				top: -4px;
			}
		}
	}

	.sent-email {
		color: rgba(255, 255, 255, 0.8);
		padding: 40px 0px !important;
	}

	.info-read-book {
		border-radius: 15px;
		border: solid 2px #1e5058;
		padding: 29px;

		@media (max-width: 990px) {
			padding: 20px;
		}

		.info-text {
			display: flex;
			align-content: center;
			line-height: 1.5;
			letter-spacing: normal;
			color: #b3c8cc;

			p {
				color: #b3c8cc;
				align-self: center;
			}
			b {
				color: #ffffff;
			}
		}

		.info-button {
			display: flex;
			align-items: center;
		}

		a {
			border-radius: 40px;
			background-color: #f36140;
			padding: 23px 0px;
			width: 100%;
			text-align: center;
			display: block;
			position: relative;
			font-family: "Nunito";
			font-size: 14px;
			color: white;
			font-weight: bold;

			i {
				position: absolute;
				right: 20px;
				top: 0px;
				bottom: 0px;
				margin-top: auto;
				margin-bottom: auto;
				height: 14px;
			}
		}
	}

	.info-read-crop {
		border-radius: 15px;
		border: solid 2px #1e5058;
		padding: 29px;
		margin-top: 15px;

		.info-text {
			display: flex;
			align-content: center;
			line-height: 1.5;
			letter-spacing: normal;
			color: #b3c8cc;
			font-size: 14px;
			p {
				color: #b3c8cc;
				align-self: center;
			}
			b {
				color: #ffffff;
			}
		}

		.info-crop {
			display: flex;
			align-items: center;
			border-radius: 15px;
			border: dotted 2px #1e5058;
			text-align: center;
			font-size: 14px;

			img {
				margin-top: 18px;
			}
			p {
				color: #b3c8cc;
				align-self: center;
			}
			b {
				color: #ffffff;
			}

			a {
				width: auto;
				display: inline;
				padding: 13px 41px;
			}
		}

		a.read-more {
			border-radius: 40px;
			background-color: #f36140;
			padding: 23px 0px;
			width: 100%;
			text-align: center;
			display: block;
			position: relative;
			font-family: "Nunito";
			font-size: 14px;
			color: white;
			font-weight: bold;

			i {
				position: absolute;
				right: 20px;
				top: 0px;
				bottom: 0px;
				margin-top: auto;
				margin-bottom: auto;
				height: 14px;
			}
		}

		a.btn-crop {
			border-radius: 40px;
			background-color: #f36140;
			text-align: center;
			display: block;
			position: relative;
			display: inline-block;
			padding: 13px 41px;
			font-family: "Nunito";
			font-size: 14px;
			color: white;
			font-weight: bold;
			//  margin-top:18px;

			// i{
			//   position: absolute;
			//   left: 15px;
			//   top: 0px;
			//   bottom: 0px;
			//   margin-top: auto;
			//   margin-bottom: auto;
			//   height: 14px;
			// }
		}
	}

	// .CANCELAR {
	//   width: 105px;
	//   height: 16px;
	//   font-family: Nunito;
	//   font-size: 12px;
	//   font-weight: 900;
	//   font-stretch: normal;
	//   font-style: normal;
	//   line-height: normal;
	//   letter-spacing: normal;
	//   color: #68596e;
	// }

	.preview {
		width: 100%;
		height: calc(372px * (9 / 16));
		overflow: hidden;
	}

	.hide {
		display: none;
	}

	.crop-upload-body {
		h1 {
			text-align: center;
			width: 100%;
			margin-bottom: 0px;
			font-family: Nunito;
			font-size: 26px;
			font-weight: 900;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: #68596e;
		}

		hr {
			margin-top: 20px;
			margin-bottom: 20px;
			width: 100%;
		}

		p {
			width: 100%;
			font-family: "Nunito";
			font-size: 18px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: #68596e;
			margin-bottom: 13px;
		}

		.cancel-modal {
			font-family: "Nunito";
			font-size: 12px;
			font-weight: 900;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #68596e;
			align-self: center;
		}

		.flex {
			display: flex;
		}
		.crop-modal {
			border-radius: 40px;
			background-color: #f36140;
			padding: 23px 0px;
			width: 100%;
			text-align: center;
			display: block;
			position: relative;
			font-family: "Nunito";
			font-size: 14px;
			font-weight: bold;
			color: white;
		}
	}
</style>
